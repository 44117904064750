<template>
  <div class="wrapper" v-if="bem.id">
    <div v-if="onlyFotos || (fotosBem && fotosBem.length)">
      <h2 v-if="title" class="diviser">
        {{ title }}
      </h2>
      <h2 v-else class="diviser">
        Fotos para Marketplace/Site
      </h2>
      <div class="m-t">
        <input type="checkbox" @change="selecionarTudoMarketplace" v-model="selecionarTudoMarketplaceModel" /> Clique para marcar todas as fotos para publicação nos meios de divulgação
      </div>
      <div class="lista-fotos-bem m-t row">
        <div v-for="foto in fotosBem" :key="foto.id" class="foto-container hoverable">
          <a :href="foto.url" target="_blank" class="foto">
            <img :src="foto.versions && foto.versions.thumb && foto.versions.thumb.url ? foto.versions.thumb.url : foto.url" width="133" />
          </a>
          <div class="row m-t-xs">
            <div class="col">
              <input type="checkbox" @change="alteraPublicacaoSite(foto, foto.site)" v-model="foto.site" /> Site
            </div>
            <div class="col text-right">
              <a @click="definirPrincipal(foto)" class="font-10 text-info hidden-nohover">Capa</a>
              <a @click="excluir(foto)" class="font-10 text-negative hidden-nohover m-l-sm">Excluir</a>
              <span v-if="foto.default" class="text-primary font-11">Principal</span>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="m-t">
      <h6 class="m-b" style="font-size: 13px">Arquivos</h6>
      <div class="bem-arquivos">
        <div v-for="arquivo in arquivos" :key="arquivo.id" class="arquivo hoverable">
          <div>
            <u-icon :name="getFileIco(arquivo)" type="fa" class="text-grey font-16" />
            <div v-if="arquivo.tipo" class="font-10 text-grey-6 m-b-xs">{{arquivo.tipo.nome}}</div>
            <div v-if="arquivo.versions && arquivo.versions.thumb && arquivo.versions.thumb.url">
              <img :src="arquivo.versions.thumb.url" class="w-full" />
            </div>
            {{arquivo.nome || arquivo.originalFilename}}
            <div class="font-10" v-if="arquivo.nome">{{arquivo.originalFilename}}</div>
          </div>
          <div class="m-t options flex justify-between hidden-nohover">
            <a v-if="arquivo.permissao < 2" :href="arquivo.url" target="_blank">Baixar</a>
            <a v-else-if="arquivo.downloading" class="text-purple">Gerando acesso...</a>
            <a v-else @click="download(arquivo)">Baixar</a>
            <a @click="excluir(arquivo)">Excluir</a>
            &lt;!&ndash; TODO: v2 - Permitir mudar permissão e tipo de arquivo por aqui &ndash;&gt;
          </div>
        </div>
        <div class="col" v-if="!arquivos || !arquivos.length">
          Nenhum arquivo anexo
        </div>
      </div>
    </div>-->

    <div v-if="!onlyFotos">
      <div class="m-t" v-for="tipo in tiposArquivos" :key="'tipo-' + tipo.id">
        <h2 class="diviser m-b">{{ tipo.nome }}</h2>
        <div class="bem-arquivos">
          <div v-for="arquivo in getArquivoPorTipo(tipo)" :key="'arquivo' + arquivo.id" class="arquivo hoverable">
            <div>
              <u-icon :name="getFileIco(arquivo)" type="fa" class="text-grey font-16" />
              <div v-if="arquivo.tipo" class="font-10 text-grey-6 m-b-xs">{{arquivo.tipo.nome}}</div>
              <div v-if="arquivo.versions && arquivo.versions.thumb && arquivo.versions.thumb.url">
                <img :src="arquivo.versions.thumb.url" class="w-full" />
              </div>
              {{arquivo.nome || arquivo.originalFilename}}
              <div class="font-10" v-if="arquivo.nome">{{arquivo.originalFilename}}</div>
              <div v-if="arquivo.validade" class="font-10 text-deep-orange m-t-sm">Válido até <strong>{{arquivo.validade.date|formatDate('dd/MM/yyyy')}}</strong></div>
            </div>
            <div class="m-t options flex justify-between hidden-nohover">
              <a v-if="arquivo.permissao < 2" :href="arquivo.url" target="_blank">Baixar</a>
              <a v-else-if="arquivo.downloading" class="text-purple">Gerando acesso...</a>
              <a v-else @click="download(arquivo)">Baixar</a>
              <a @click="excluir(arquivo)">Excluir</a>
              <!-- TODO: v2 - Permitir mudar permissão e tipo de arquivo por aqui -->
            </div>
          </div>
          <div class="col" v-if="!arquivos || !arquivos.length">
            Nenhum arquivo anexo
          </div>
        </div>
      </div>
    </div>


    <u-btn v-if="!hideBtn" @click="uploadArquivos" label="Fazer upload" color="primary" class="m-t-lg" icon="upload"
           icon-type="fa" push />
    <!--

    <erp-label tag="div" class="erp-label-white w-full m-t-xxl" label="Enviar Arquivos ou Imagens">
      <div class="row" style="margin: -16px -20px;">
        <div class="col wrapper m-t" style="width: 50%">
          <div class="row erp-row m-r-lg">
            <div class="col">
              <tipo-arquivo-select label-width="100px" field-view="lr" v-model="upload.tipoArquivo" />
            </div>
          </div>
          <div class="row erp-row m-r-lg">
            <div class="col">
              <erp-s-field
                  view="lr"
                  label-width="100px"
                  label="Descrição:"
              >
                <erp-input v-model="upload.descricao" />
              </erp-s-field>
            </div>
          </div>
        </div>
        <div class="col bg-grey-2 flex items-center justify-center" style="width: 50%">
          <div class="font-11 text-grey-7">
            Solte os arquivos aqui<br>
            Ou <strong>clique</strong> para anexar
          </div>
        </div>
      </div>

    </erp-label>-->
  </div>
  <div v-else class="m-t">Salve o bem primeiro antes de anexar arquivos</div>
</template>

<script>
import uploadArquivos from '../../windows/uploadArquivos'
import {deleteArquivoBem, updateArquivoBem, uploadArquivoBem, definirFotoPrincipal} from '@/domain/bem/services'
import fileType from '../../../../mixins/fileType'
import bemArquivoMixin from "@/components/bem/components/include/arquivos/bemArquivoMixin"

export default {
  name: 'ArquivosBem',
  mixins: [fileType, bemArquivoMixin],
  props: {
    bem: {required: true},
    title: {required: false},
    hideBtn: {
      type: Boolean,
      required: false
    },
    onlyFotos: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      selecionarTudoMarketplaceModel: false
    }
  },
  computed: {
    fotosBem () {
      if (!this.bem.arquivos) {
        return null
      }
      return this.bem.arquivos.filter(arquivo => arquivo.tipo && arquivo.tipo.nome.toLowerCase() === 'foto site') // TODO: REVER
    },
    arquivos () {
      if (!this.bem.arquivos) {
        return null
      }
      return this.bem.arquivos.filter(arquivo => !arquivo.tipo || !(arquivo.tipo.nome.toLowerCase() === 'foto site')) // TODO: REVER
    },
    tiposArquivos () {
      if (!this.bem.arquivos) {
        return null
      }
      let tipos = []
      this.bem.arquivos.slice().map(arquivo => {
        if (!arquivo.tipo || arquivo.tipo.nome.toLowerCase() === 'foto site') return
        !tipos.find(a => a.id === arquivo.tipo.id) && tipos.push(arquivo.tipo)
      })
      return tipos
    }
  },
  methods: {
    getFileIco (file) {
      let path = file.originalFilename.split('.')
      let extension = path.pop()
      return this.bindIcon(extension)
    },
    uploadArquivos: uploadArquivos,
    uploadService (data) {
      console.log('Upload', data)
      return uploadArquivoBem(this.bem.id, data)
    },
    uploadServiceSuccess (response) {
      if (!Array.isArray(this.bem.arquivos)) {
        this.bem.arquivos = []
      }
      this.bem.arquivos.push(response.data)
    },
    excluir (arquivo) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um arquivo',
        message: 'Você tem certeza que deseja remover este arquivo do cadastro do bem? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        console.log('Removendo...', arquivo)
        deleteArquivoBem(this.bem.id, arquivo.id)
          .then(response => {
            console.log(response.data)
            this.bem.arquivos.splice(this.bem.arquivos.indexOf(arquivo), 1)
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Arquivo excluído com sucesso.'})
          })
          .catch((error) => {
            console.log(error)
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    definirPrincipal (arquivo) {
      this.$uloc.dialog({
        title: 'Foto capa',
        message: 'Definir esta foto como principal?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        console.log('Definindo principal...', arquivo)
        definirFotoPrincipal(this.bem.id, arquivo.id)
          .then(response => {
            console.log(response.data)
            arquivo.default = true
            this.bem.image = response.data.image
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Arquivo definido como capa.'})
          })
          .catch((error) => {
            console.log(error)
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    alteraPublicacaoSite (foto, status) {
      console.log(foto, status)
      updateArquivoBem(this.bem.id, foto.id, {
        id: foto.id,
        site: status
      })
      .then(response => {
        console.log(response.data)
      })
      .catch((error) => {
        this.alertApiError(error)
      })
    },
    getArquivoPorTipo (t) {
      if (!this.bem.arquivos) {
        return null
      }
      return this.bem.arquivos.filter(arquivo => arquivo.tipo && arquivo.tipo.id === t.id)
    },
    selecionarTudoMarketplace () {
      this.$uloc.dialog({
        title: 'Autorizar publicação de fotos',
        message: 'Tem certeza que deseja publicar todas as fotos?',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.fotosBem.filter(f => !f.site).map(foto => {
          foto.site = true
          this.alteraPublicacaoSite(foto, true)
        })
      }).catch(() => {
        this.selecionarTudoMarketplaceModel = false
      })
    }
  }
}
</script>
