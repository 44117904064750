<template>
  <div>
<!--    <h2 class="diviser">
      Informações complementares
    </h2>-->

    <div>
      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="tratamento-taxas" />
      </e-row>

<!--      <e-row mr>
        <e-col class="flex justify-between">
          <erp-s-field class="full-width" label="Observações internas">
            <textarea class="sl-textarea full-width" rows="5" v-model="bem.observacoesInternas"></textarea>
          </erp-s-field>
        </e-col>
      </e-row>-->
      <div class="full-width m-t">
        <gestao-financeiro only-debitos :tags="['taxa']" />
      </div>
      <checklist ref="checklist" secao="tratamento-taxas"  />
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import BemCampoSecao from "@/components/bem2/components/include/CamposSecao"
import GestaoFinanceiro from "@/components/bem2/components/include/Financeiro"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "TratamentoTaxas",
  directives: {money: VMoney},
  components: {
    BemCampoSecao,
    GestaoFinanceiro,
    Checklist
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {
      money: REAL_BRL
    }
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
