<template>
  <div>
<!--    <h2 class="diviser">
      Informações complementares
    </h2>-->

<!--    <div v-if="bemContainer.isImovel" class="m-t form-column-align-bottom">
      <e-row mr>
        <e-col style="max-width: 150px">
          <erp-s-field label="Possui condomínio?">
            <erp-select v-model="bem.imovel.possuiCondominio" :options="[
                {label: 'Sim', value: true},
                {label: 'Não', value: false},
            ]" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="Valor do condomínio">
            <erp-input :disable="!bem.imovel.possuiCondominio" v-model.lazy="bem.imovel.valorCondominio" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field label="Possui IPTU?">
            <erp-select v-model="bem.imovel.possuiIptu" :options="[
                {label: 'Sim', value: true},
                {label: 'Não', value: false},
            ]" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="Valor do IPTU">
            <erp-input :disable="!bem.imovel.possuiIptu" v-model.lazy="bem.imovel.valorIptu" v-money="money" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field label="Parcelas do IPTU">
            <erp-select v-model="bem.imovel.periodicidadeIptu" :options="[
                {label: '1', value: 1},
                {label: '2', value: 2},
                {label: '3', value: 3},
                {label: '4', value: 4},
                {label: '5', value: 5},
                {label: '6', value: 6},
                {label: '7', value: 7},
                {label: '8', value: 8},
                {label: '9', value: 9},
                {label: '10', value: 10},
                {label: '11', value: 11},
                {label: '12', value: 12}
            ]" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 120px">
          <erp-s-field label="Possui outras despesas mensais fixas?">
            <erp-select v-model="bem.imovel.possuiOutrasDespesas" :options="[
                {label: 'Sim', value: true},
                {label: 'Não', value: false},
            ]" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="Valor das despesas mensais">
            <erp-input :disable="!bem.imovel.possuiOutrasDespesas" v-model.lazy="bem.imovel.valorOutrasDespesas" v-money="money" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="max-width: 120px">
          <erp-s-field label="N.º IPTU">
            <erp-input v-model="bem.imovel.numeroIptu" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="N.º medidor de energia">
            <erp-input v-model="bem.imovel.numeroMedidorEnergia" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="N.º medidor de água">
            <erp-input v-model="bem.imovel.numeroMedidorAgua" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="N.º medidor de gás">
            <erp-input v-model="bem.imovel.numeroMedidorGas" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>-->

    <div>
      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="tratamento-impostos" />
      </e-row>

<!--      <e-row mr>
        <e-col class="flex justify-between">
          <erp-s-field class="full-width" label="Observações internas">
            <textarea class="sl-textarea full-width" rows="5" v-model="bem.observacoesInternas"></textarea>
          </erp-s-field>
        </e-col>
      </e-row>-->
      <div class="full-width m-t">
        <gestao-financeiro only-debitos :tags="['imposto']" />
      </div>
      <checklist ref="checklist" secao="tratamento-impostos"  />
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {ErpSelect} from "uloc-vue-plugin-erp"
import {REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import BemCampoSecao from "@/components/bem2/components/include/CamposSecao"
import GestaoFinanceiro from "@/components/bem2/components/include/Financeiro"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "TratamentoImpostos",
  directives: {money: VMoney},
  components: {
    BemCampoSecao,
    // ErpSelect,
    GestaoFinanceiro,
    Checklist
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {
      money: REAL_BRL
    }
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
