import http from '../../../utils/services/http'


export const resumo = (bem) => {
    let url = `/api/bem/${bem}/resumoFinanceiro`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
