<template>
  <div>
    <checklist ref="checklist" secao="saneamento"  />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "SaneamentoChecklist",
  mixins: [CadastroBemMixin],
  data () {
    return {
    }
  },
  components: {
    Checklist
  }
}
</script>
