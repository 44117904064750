<template>
  <div>
    <h2 v-if="onlyDebitos" class="diviser">
      Débitos <span class="m-t wrapper-xs" v-if="tags && tags.length">{{tags.join(', ')}}</span>
    </h2>
    <h2 v-else class="diviser">
      Financeiro
    </h2>

    <div class="m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="sl-table no-bottom no-scroll m-t-md"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :style="col.name === 'valor' ? 'text-align: right !important' : ''" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-right">
              <span class="cursor-pointer" @click="listOptions">Opções</span>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props" :class="{'u-table-item-destac': props.row.isToday}">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="categoria" :props="props">
            {{ props.row.categoria.descricao }}
          </u-td>
          <u-td key="vencimento" :props="props">
            {{ props.row.dataVencimento|formatDate('dd/MM/yyyy') }}
          </u-td>
          <u-td key="lancamento" :props="props">
            {{ props.row.createdAt|formatDate('dd/MM/yyyy') }}
          </u-td>
          <u-td class="td-limit" key="descricao" :props="props">
            {{ props.row.descricao }}
          </u-td>
          <u-td key="status" :props="props">
            <span v-if="props.row.pago" class="text-positive">Pago</span>
            <span v-else>Aberto</span>
          </u-td>
          <u-td key="valor" class="text-right" :props="props">
            R$ {{ props.row.valor|moeda }}
          </u-td>
          <u-td key="options" class="text-right" :props="props">
            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)"
                           @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
      <h2 class="diviser"></h2>
      <div v-if="!onlyDebitos" class="text-right">
        <div class="m-t text-negative">Total de Despesas: R$ {{counts.despesa|moeda}}</div>
        <div class="m-t-xs">Despesas pagas: R$ {{counts.despesasPagas|moeda}}</div>
        <div class="m-t-xs">Despesas em aberto: R$ {{counts.despesasNaoPagas|moeda}}</div>

        <div class="m-t text-positive">Total de Receitas: R$ {{counts.receita|moeda}}</div>
        <div class="m-t-xs">Receitas pagas: R$ {{counts.receitasPagas|moeda}}</div>
        <div class="m-t-xs">Receitas em aberto: R$ {{counts.receitasNaoPagas|moeda}}</div>
      </div>
      <div v-else class="text-right">
        <div class="m-t">Total: R$ {{counts.despesa|moeda}}</div>
        <div class="m-t-xs text-positive">Total pago: R$ {{counts.despesasPagas|moeda}}</div>
        <div class="m-t-xs text-negative">Total devido: R$ {{counts.despesasNaoPagas|moeda}}</div>
      </div>
      <div v-if="bem.id && !loading" class="m-t">
        <u-btn v-if="onlyDebitos" @click="novo('d')" class="sl-btn" color="positive" no-caps label="Registrar um novo débito"/>
        <u-btn v-if="!onlyDebitos" @click="novo('d')" class="sl-btn" color="negative" no-caps label="Registrar um novo débito"/>
        <u-btn v-if="!onlyDebitos" @click="novo('r')" class="sl-btn m-l" color="positive" no-caps label="Registrar um novo crédito"/>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {listConta, deleteConta} from "@/domain/financeiro/services"
import {listStorage} from "@/components/bem/components/include/financeiro/config/list"
import modelWindow from "@/components/financeiro/windows/conta"
import ListMixin from "@/reuse/list/ListMixin"

export default {
  name: "BemFinanceiro",
  props: {
    onlyDebitos: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array
    }
  },
  data() {
    return {
      listStorage: this.listStorage,
      loading: false,
      listData: null,
      counts: {}
    }
  },
  components: {
  },
  mounted() {
    this.load()
  },
  mixins: [CadastroBemMixin, ListMixin],
  watch: {
    'onlyDebitos' (v) {
      this.load()
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {},
  methods: {
    request({pagination, filter}) {
      this.table.loading = true
      const extraFilters = [
          'relationEntity=bem',
          'relationEntityId=' + this.bem.id
      ]

      if (this.onlyDebitos) {
        extraFilters.push('tipo=2')
      }
      if (this.tags) {
        extraFilters.push('tags=' + this.tags.join(','))
      }
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('&')}`)

      listConta(1000, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.counts = data.counts || {}
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    modelWindow,
    novo(tipo = 'd') {
      this.modelWindow(null, tipo, 'bem', this.bem.id, null, null, this.tags)
    },
    abrir(id) {
      this.modelWindow(id, this.bem)
    },
    excluir(id) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteConta(id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
