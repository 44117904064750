<template>
  <div>
    <div class="wrapper">
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              class="wrap label-wrap"
              label="Observações sobre a conservação:"
          >
            <textarea v-model="bem.observacaoConservacao" class="full-width sl-textarea" rows="3" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Valor Inicial:"
              icon-help="Usado somente para leilões. O valor inicial do lance para este bem"
          > <!-- VALOR MERCADO em caso de outros bens -->
            <erp-input v-model.lazy="bem.valorInicial" v-money="money" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Valor de Mercado:"
              class="wrap label-wrap"
          > <!-- VALOR MERCADO em caso de outros bens -->
            <erp-input v-model.lazy="bem.valorMercado" v-money="money" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Valor da avaliação:"
          >
            <erp-input v-model.lazy="bem.valorAvaliacao" v-money="money" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="% da Avaliação/Mercado:"
          >
            <erp-input v-model="bemContainer.fake.porcentagemAvaliacaoSobreValorMercado" disable />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Valor Mínimo:"
              class="wrap label-wrap"
          >
            <erp-input v-model.lazy="bem.valorMinimo" v-money="money" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="% Mínimo/Mercado:"
          >
            <erp-input v-model="bemContainer.fake.porcentagemMinimoSobreValorMercado" disable />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Avarias:"
              class="wrap label-wrap"
          >
            <textarea v-model="bem.avarias" class="full-width sl-textarea" rows="5" />
          </erp-s-field>
        </e-col>
      </e-row>

      <div class="w-full m-t-xl">
        <e-row mr>
          <e-col style="max-width: 340px">
            <erp-s-field
                view="lr"
                label-width="110px"
                label="Avaliador:"
                class="wrap label-wrap"
            >
              <erp-input v-model="bem.extra.avaliacaoTerceiros.avaliador" /> <!-- TODO: WindowSearch -->
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 240px">
            <erp-s-field
                view="lr"
                label="Remessa:"
            >
              <erp-input v-model="bem.extra.avaliacaoTerceiros.remessa" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="max-width: 220px">
            <erp-s-field
                view="lr"
                label-width="110px"
                label="Valor de Mercado:"
                class="wrap label-wrap"
            >
              <erp-input v-model="bem.extra.avaliacaoTerceiros.valorMercado" />
            </erp-s-field>
          </e-col>

          <e-col style="max-width: 240px">
            <erp-s-field
                view="lr"
                label="Relatório técnico:"
                style="line-height: 20px"
            >
              <btn-mostrar v-model="mostrarRelatorioAvaliacaoTerceiro"
                           @click="mostrarRelatorioAvaliacaoTerceiro = !mostrarRelatorioAvaliacaoTerceiro" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr class="m-t" v-show="mostrarRelatorioAvaliacaoTerceiro">
          <textarea v-model="bem.extra.avaliacaoTerceiros.relatorio" class="full-width sl-textarea" rows="10" />
        </e-row>
      </div>
    </div>

    <bem-laudos-avaliacao class="m-t" />
    <checklist ref="checklist" secao="tratamento-avaliacao"  />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import BtnMostrar from '../../../../reuse/btn/Mostrar'
import {VMoney} from "v-money"
import {REAL_BRL} from "@/utils/money"
import BemLaudosAvaliacao from "@/components/bem/components/include/LaudosAvaliacao"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "Avaliacao",
  directives: {money: VMoney},
  components: {
    BemLaudosAvaliacao,
    BtnMostrar,
    Checklist
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {
      money: REAL_BRL,
      mostrarRelatorioAvaliacaoTerceiro: false
    }
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
