<template>
  <div>
    <div class="erp-window erp-window-modern bem2-uploader-inline">
      <window-uploader
          :enable-title-file="true"
          :enable-validade="true"
          :permissions="[
          {
            label: 'Público',
            value: 0
          },
          {
            label: 'Arrematante',
            value: 1
          },
          {
            label: 'Comitente',
            value: 2
          },
          {
            label: 'Interno',
            value: 100
          }
        ]"
          :type-file-list="list"
          :upload-service="uploadService"
          :success-callback="uploadServiceSuccess"
      />
    </div>
    <arquivos-bem :bem="bem" hide-btn />
  </div>
</template>

<script>
import WindowUploader from '@/reuse/upload/WindowUploader'
import {listTiposArquivo as list, uploadArquivoBem} from '@/domain/bem/services'
import uploadArquivos from "@/components/bem2/windows/uploadArquivos"
import CadastroBemMixin from "@/components/bem2/components/include/CadastroBemMixin"
import ArquivosBem from "@/components/bem2/components/include/Arquivos"

export default {
  name: "UploaderInline",
  mixins: [CadastroBemMixin],
  components: {ArquivosBem, WindowUploader},
  methods: {
    list: list,
    uploadArquivos: uploadArquivos,
    uploadService(data) {
      console.log('Upload', data)
      return uploadArquivoBem(this.bem.id, data)
    },
    uploadServiceSuccess(response) {
      if (!Array.isArray(this.bem.arquivos)) {
        this.bem.arquivos = []
      }
      this.bem.arquivos.push(response.data)
    },
  }
}
</script>

<style lang="stylus">
.bem2-uploader-inline {
  .w-m-footer {
    display flex
    justify-content space-between
    padding 0 !important
    > .row {
      width 100%
    }
  }
}
</style>
