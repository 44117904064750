<template>
  <div class="w-full">
    <div class="row">
      <div style="display: none">
        {{ this.bem.pericias }}
      </div>
      <div v-for="pericia in pericias" class="col pericia-col" :class="{'pericia-selected': pericia.selected}"
           style="width: 33%">
        <label class="flex items-center no-select">
          <u-checkbox @click.native="change(pericia)" v-model="pericia.selected"/>
          <span class="no-select m-l-xs">{{ pericia.label }}</span></label>
      </div>
    </div>
    <div class="w-full m-t">
      <erp-s-field
          view="tl"
          label="Edital:"
      >
        <textarea :disabled="noedit.edital" v-model="bem.edital" class="sl-textarea w-full wrapper-xs" rows="4"/>
        <div><label><u-checkbox v-model="noedit.edital"/> Gerar automaticamente baseado nas configurações do sistema</label></div>
      </erp-s-field>
    </div>

    <div class="w-full m-t">
      <erp-s-field
          view="tl"
          label="Ata:"
      >
        <textarea :disabled="noedit.ata" v-model="bem.ata" class="sl-textarea w-full wrapper-xs" rows="4"/>
        <div><label><u-checkbox v-model="noedit.ata"/> Gerar automaticamente baseado nas configurações do sistema</label></div>
      </erp-s-field>
    </div>

    <div class="w-full m-t">
      <erp-s-field
          view="tl"
          label="Catálogo:"
      >
        <textarea :disabled="noedit.catalogo" v-model="bem.catalogo" class="sl-textarea w-full wrapper-xs" rows="4"/>
        <div><label><u-checkbox v-model="noedit.catalogo"/> Gerar automaticamente baseado nas configurações do sistema</label></div>
      </erp-s-field>
    </div>

    <div class="w-full m-t">
      <erp-s-field
          view="tl"
          label="Nota:"
      >
        <textarea :disabled="noedit.nota" v-model="bem.nota" class="sl-textarea w-full wrapper-xs" rows="4"/>
        <div><label><u-checkbox v-model="noedit.nota"/> Gerar automaticamente baseado nas configurações do sistema</label></div>
      </erp-s-field>
    </div>
  </div>
</template>

<script>
import {listPericias} from '../../../../domain/bem/services'
import {UCheckbox} from 'uloc-vue'
import {ErpSField} from 'uloc-vue-plugin-erp'
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin";

export default {
  name: 'PericiasSelect',
  mixins: [CadastroBemMixin],
  props: {
    value: {required: true},
    tipoBem: {required: false}
  },
  data() {
    return {
      loading: false,
      pericias: [],
      noedit: {
        edital: true,
        ata: true,
        catalogo: true,
        nota: true
      }
    }
  },
  mounted() {
    this.load()
  },
  components: {UCheckbox, ErpSField},
  watch: {
    tipoBem(v) {
      console.log(v)
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  computed: {},
  methods: {
    load() {
      listPericias(this.tipoBem)
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: obj.nome,
                value: obj.id,
                selected: this.checkChecked(obj.id)
              }
            })

            this.pericias = list
          })
          .catch((error) => {
            this.loading = false

          })
    },
    checkChecked(id) {
      if (!this.bem.pericias) {
        return false
      }
      let find = this.bem.pericias.find(item => {
        return item.pericia.id === id
      })
      return !!find
    },
    adicionaDescricao(tipo, descricao) {
      this.$nextTick(() => {
        let atual = String(this.bem[tipo])
        if (!atual.includes(descricao)) {
          console.log('Adiciona descricao', descricao)
          atual = atual + (atual.length ? ', ' : '') + descricao
        }
        this.bem[tipo] = atual
      })
    },
    removeDescricao(tipo, descricao) {
      console.log('XXX', descricao)
      this.$nextTick(() => {
        let atual = String(this.bem[tipo])
        if (atual.includes(descricao)) {
          console.log('Remove descricao', descricao)
          atual = atual.replace(', ' + descricao, '')
          atual = atual.replace(descricao, '')
        }
        this.bem[tipo] = atual
      })
    },
    change(pericia) {
      this.$nextTick(() => {
        /* this.ata = ''
      this.catalogo = ''
      this.nota = '' */
        this.bem.pericias = []
        this.pericias.map((a) => {
          if (a.selected) {
            this.bem.pericias.push({
              pericia: a.id
            })
          }
            if (a.id === pericia.id) {
              console.log(a)
              if (a.ata) {
                a.selected ? this.adicionaDescricao('ata', a.descricao) : this.removeDescricao('ata', a.descricao)
              }
              if (a.catalogo) {
                a.selected ? this.adicionaDescricao('catalogo', a.descricao) : this.removeDescricao('catalogo', a.descricao)
              }
              if (a.nota) {
                a.selected ? this.adicionaDescricao('nota', a.descricao) : this.removeDescricao('nota', a.descricao)
              }
              console.log('Mudou perícia')
            }
        })
      })

    }
  }
}
</script>
