var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('e-row',{attrs:{"mr":""}},[_c('bem-campo-secao',{attrs:{"css":"erp-row-view simple col-limit-4 v3 view-wrap","secao":"tratamento-taxas"}})],1),(_vm.bemContainer.isImovel)?_c('div',{staticClass:"form-column-align-bottom"},[_c('e-row',{attrs:{"mr":""}},[_c('e-col',{staticStyle:{"max-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Possui condomínio?"}},[_c('erp-select',{attrs:{"options":[
              {label: 'Sim', value: true},
              {label: 'Não', value: false},
          ]},model:{value:(_vm.bem.imovel.possuiCondominio),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "possuiCondominio", $$v)},expression:"bem.imovel.possuiCondominio"}})],1)],1),_c('e-col',{staticStyle:{"max-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Valor do condomínio"}},[_c('erp-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"disable":!_vm.bem.imovel.possuiCondominio},model:{value:(_vm.bem.imovel.valorCondominio),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "valorCondominio", $$v)},expression:"bem.imovel.valorCondominio"}})],1)],1),_c('e-col',{staticStyle:{"max-width":"120px"}},[_c('erp-s-field',{attrs:{"label":"Possui IPTU?"}},[_c('erp-select',{attrs:{"options":[
              {label: 'Sim', value: true},
              {label: 'Não', value: false},
          ]},model:{value:(_vm.bem.imovel.possuiIptu),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "possuiIptu", $$v)},expression:"bem.imovel.possuiIptu"}})],1)],1),_c('e-col',{staticStyle:{"max-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Valor do IPTU"}},[_c('erp-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"disable":!_vm.bem.imovel.possuiIptu},model:{value:(_vm.bem.imovel.valorIptu),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "valorIptu", $$v)},expression:"bem.imovel.valorIptu"}})],1)],1),_c('e-col',{staticStyle:{"max-width":"120px"}},[_c('erp-s-field',{attrs:{"label":"Parcelas do IPTU"}},[_c('erp-select',{attrs:{"options":[
              {label: '1', value: 1},
              {label: '2', value: 2},
              {label: '3', value: 3},
              {label: '4', value: 4},
              {label: '5', value: 5},
              {label: '6', value: 6},
              {label: '7', value: 7},
              {label: '8', value: 8},
              {label: '9', value: 9},
              {label: '10', value: 10},
              {label: '11', value: 11},
              {label: '12', value: 12}
          ]},model:{value:(_vm.bem.imovel.periodicidadeIptu),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "periodicidadeIptu", $$v)},expression:"bem.imovel.periodicidadeIptu"}})],1)],1)],1),_c('e-row',{attrs:{"mr":""}},[_c('e-col',{staticStyle:{"max-width":"120px"}},[_c('erp-s-field',{attrs:{"label":"Possui outras despesas mensais fixas?"}},[_c('erp-select',{attrs:{"options":[
              {label: 'Sim', value: true},
              {label: 'Não', value: false},
          ]},model:{value:(_vm.bem.imovel.possuiOutrasDespesas),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "possuiOutrasDespesas", $$v)},expression:"bem.imovel.possuiOutrasDespesas"}})],1)],1),_c('e-col',{staticStyle:{"max-width":"150px"}},[_c('erp-s-field',{attrs:{"label":"Valor das despesas mensais"}},[_c('erp-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"disable":!_vm.bem.imovel.possuiOutrasDespesas},model:{value:(_vm.bem.imovel.valorOutrasDespesas),callback:function ($$v) {_vm.$set(_vm.bem.imovel, "valorOutrasDespesas", $$v)},expression:"bem.imovel.valorOutrasDespesas"}})],1)],1)],1)],1):_vm._e(),_c('historico-financeiro',{staticClass:"m-t"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }