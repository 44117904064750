<template>
  <div>
    <h2 class="diviser">
      Processo ativo
    </h2>

    <div class="m-t">
      <e-row mr>
        <e-col style="max-width: 800px">
          <div class="flex items-center">
            <erp-s-field
                view="ll"
                label="Número do Processo:"
                label-width="120px"
                style="min-width: 500px"
            >
               <processo-input @processoEncontrado="processoEncontrado" @naoEncontrado="processoNaoEncontrado" v-model="bem.processoNumero" />
            </erp-s-field>
            <div class="m-l" v-if="bem.processo">Processo registrado com ID <a @click="gotoProcesso(bem.processo)">#{{bem.processo.id}}</a></div>
          </div>
        </e-col>
      </e-row>

      <e-row mr>

        <e-col style="max-width: 500px">
          <erp-s-field
              view="ll"
              label="Exequente"
              label-width="120px"
          >
            <erp-input simple-border v-model="bem.processoExequente"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 500px">
          <erp-s-field
              view="ll"
              label="Executado:"
              label-width="120px"
          >
            <erp-input simple-border v-model="bem.processoExecutado"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 500px">
          <erp-s-field
              view="ll"
              label="Vara:"
              label-width="120px"
          >
            <erp-input simple-border v-model="bem.processoVara"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col style="max-width: 500px">
          <erp-s-field
              view="ll"
              label="Comarca:"
              label-width="120px"
          >
            <erp-input simple-border v-model="bem.processoComarca"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="processo" />
      </e-row>

    </div>

    <bem-processo-partes class="m-t-lg" />
    <historico-processos class="m-t-lg" />

    <div>

      <checklist ref="checklist" secao="tratamento-processos" />
      <!-- CHECKLIST JUDICIAL -->
      <div class="dtitle two-lines pos-rlt m-t-lg">
        Checklist Auxiliar Judicial
        <u-icon @click.native="isDescMin = !isDescMin" :name="!isDescMin ? 'minus-square' : 'expand'"
                class="cursor-pointer absolute-center-right" type="fa" color="blue-grey-3">
          <u-tooltip :offset="[5,5]">{{ !isDescMin ? 'Ocultar' : 'Restaurar' }}</u-tooltip>
        </u-icon>
      </div>

      <div v-show="!isDescMin" class="m-t-lg w-full form-column-mt form-column-pr">
        <p class="font-11 font-bold">Intimação das partes</p>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Advogado constituído autor:"
            >
              <erp-checkbox v-model="checklistJudicial.advogadoConstituidoAutor"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Advogado constituído réu:"
            >
              <erp-checkbox v-model="checklistJudicial.advogadoConstituidoReu"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Intimação pessoal penhora:"
            >
              <erp-checkbox v-model="checklistJudicial.intimacaoPessoalPenhora"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <date-input
                :disable="!checklistJudicial.intimacaoPessoalPenhora"
                v-model="checklistJudicial.dataIntimacaoPessoalPenhora"
                label="Data da Intimação:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Intimação pessoal Avaliação:"
            >
              <erp-checkbox v-model="checklistJudicial.intimacaoPessoalAvaliacao"/>
            </erp-s-field>
          </e-col>
          <e-col v-if="checklistJudicial.intimacaoPessoalAvaliacao">
            <date-input
                v-model="checklistJudicial.dataIntimacaoPessoalAvaliacao"
                label="Data Intimação:"
            ></date-input>
          </e-col>
          <e-col>
            <erp-s-field
                label="Intimação Pessoal Leilao:"
            >
              <erp-checkbox v-model="checklistJudicial.intimacaoPessoalLeilao"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <date-input
                :disable="!checklistJudicial.intimacaoPessoalLeilao"
                v-model="checklistJudicial.dataIntimacaoPessoalLeilao"
                label="Data:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Estado civil:"
            >
              <erp-select v-model="checklistJudicial.estadoCivil" :options="estadoCivilList"/>
            </erp-s-field>
          </e-col>
          <e-col v-if="checklistJudicial.estadoCivil === ESTADO_CIVIL_CASADO">
            <erp-s-field
                label="Intimacao cônjuge:"
            >
              <erp-checkbox v-model="checklistJudicial.intimacaoConjuge"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <date-input
                :disable="!checklistJudicial.intimacaoConjuge"
                v-model="checklistJudicial.dataIntimacaoConjuge"
                label="Data Intimação:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Coproprietário:"
            >
              <erp-checkbox v-model="checklistJudicial.coproprietario"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr v-if="checklistJudicial.coproprietario">
          <e-col>
            <erp-s-field
                label="Observações sobre o coproprietário:"
            >
                <textarea v-model="checklistJudicial.observacoesCoproprietario" class="full-width sl-textarea"
                          rows="4"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <p class="font-11 font-bold m-t-lg">Intimação dos credores</p>
        <e-row mr>
          <e-col style="max-width: 260px">
            <date-input
                v-model="checklistJudicial.dataRgi"
                label="Averbações no RGI até o dia:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Informações RGI:"
            >
              <textarea v-model="checklistJudicial.averbacoesRgi" class="full-width sl-textarea" rows="4"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="max-width: 100px">
            <erp-s-field
                label="Cientificações:"
            >
              <erp-checkbox v-model="checklistJudicial.cientificacoes"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Informações cientificações:"
            >
              <textarea :disabled="checklistJudicial.cientificacoes" v-model="checklistJudicial.averbacoesRgi"
                        class="full-width sl-textarea" rows="7"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Condomínio:"
            >
              <erp-checkbox v-model="checklistJudicial.condominio"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Autor é o condomínio:"
            >
              <erp-checkbox :disable="!checklistJudicial.condominio" v-model="checklistJudicial.condominioAutor"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Valor débito:"
            >
              <erp-input simple-border v-model.lazy="checklistJudicial.valorDebito" v-money="money"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 220px">
            <date-input
                v-model="checklistJudicial.valorDebitoAtualizadoEm"
                label="Atualizado em:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Levantamento com o DETRAN:"
            >
              <erp-checkbox v-model="checklistJudicial.levantamentoDetran"/>
            </erp-s-field>
          </e-col>
          <e-col v-if="checklistJudicial.levantamentoDetran">
            <date-input
                v-model="checklistJudicial.dataLevantamentoDetran"
                label="Data do levantamento com o Detran:"
            ></date-input>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Observações sobre o checklist judicial:"
            >
              <textarea v-model="checklistJudicial.observacoes" class="full-width sl-textarea" rows="7"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import DateInput from "@/reuse/input/Date"
import {ErpCheckbox, ErpSelect} from "uloc-vue-plugin-erp"
import {ESTADO_CIVIL_CASADO, estadosCivis} from "@/domain/processos/helpers/estado-civil"
import {VMoney} from "v-money"
import {REAL_BRL} from "@/utils/money"
import BemProcessoPartes from "@/components/bem/components/include/Partes"
import HistoricoProcessos from "@/components/bem/components/include/HistoricoProcessos"
import ProcessoInput from "@/components/bem/components/include/ProcessoInput"
import GlobalProcessoMixin from "@/components/processos/globalProcessoMixin"
import {newProcesso} from "@/domain/processos/services"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "BemProcesso",
  directives: {money: VMoney},
  components: {
    BemCampoSecao,
    ProcessoInput,
    HistoricoProcessos,
    BemProcessoPartes,
    DateInput,
    ErpSelect,
    ErpCheckbox,
    Checklist
  },
  mounted() {
  },
  mixins: [CadastroBemMixin, GlobalProcessoMixin],
  data() {
    return {
      money: REAL_BRL,
      estadoCivilList: estadosCivis,
      ESTADO_CIVIL_CASADO,
      isDescMin: false,
      router: this.bemContainer.router
    }
  },
  watch: {},
  computed: {
    checklistJudicial() {
      return this.bemContainer.checklistJudicial
    }
  },
  methods: {
    processoNaoEncontrado (numero) {
      this.bem.processo = null
      this.$uloc.dialog({
        title: 'Processo não encontrado',
        message: `O processo ${numero} não foi encontrato no sistema, deseja criá-lo?`,
        ok: 'Sim',
        cancel: 'Não, apenas manter o número no cadastro do bem',
        noCaps: true
      }).then(() => {
        this.$uloc.loading.show({message: 'Registrando o processo'})
        newProcesso ({
          numero: numero
        })
        .then(response => {
          this.$uloc.loading.hide()
          this.bem.processo = response.data
        })
        .catch(error => {
          this.$uloc.loading.hide()
          this.alertApiError(error)
        })
      })
      .catch(() => {})
    },
    processoEncontrado (processo) {
      console.log('Encontrado', processo)
      this.bem.processo = processo
    }
  }
}
</script>
