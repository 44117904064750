<template>
  <div>
    <div class="m-t">
      Nenhuma integração configurada.
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"

export default {
  name: "MarketplaceApi",
  components: {
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
</script>
