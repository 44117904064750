<template>
  <div class="wrapper">
    <div class="wrapper-sm">
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Comitente:"
              label-width="90px"
              class="m-r-n-xs"
          >
            <div class="m-l-xs"><comitente-input v-model="bem.comitente" :finded-data-obj="bemContainer.cache.comitenteSelected"
                                                 @updateSelected="(data) => bemContainer.cache.comitenteSelected = data" /></div>
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row mr>

        <e-col style="max-width: 244px; min-width: 294px">
          <tipo-bem-select ref="tipoPai" required label-width="90px" simple-border label="Tipo de bem:"
                           field-view="lr"
                           v-model="bem.tipoPai" @load="bemContainer.loadTipoBemData" @selected="bemContainer.selecionaTipoBem" />
        </e-col>

        <e-col style="max-width: 244px; min-width: 294px">
          <subtipo-bem-select v-if="bemContainer.tipoBemData && bemContainer.tipoBemData.length > 0" required :disable="!bem.tipoPai"
                              :tipo="bem.tipoPai" :tipo-data="bemContainer.tipoBemData"
                              label-width="90px" simple-border
                              label="Classificação:" field-view="lr" v-model="bem.tipo"
                              @selected="bemContainer.selecionaTipoBem" />
        </e-col>

        <e-col style="min-width: 184px">
          <erp-s-field
              view="lr"
              label="Processo:"
          >
            <erp-input
                simple-border
                v-model="bem.processoNumero"
                readonly
                v-mask="'#######-##.####.#.##.####'"
            >
            </erp-input>
          </erp-s-field>
        </e-col>

      </e-row>

      <e-row mr>
        <e-col class="input-font-bold" style="min-width: 184px">
          <erp-s-field
              view="lr"
              label="Descrição:"
              label-width="90px"
          >
            <erp-input
                autofocus
                simple-border
                v-model="bem.descricao"
            >
            </erp-input>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 167px">
          <status-select label="Status:" placeholder="Selecione"
                         field-view="lr"
                         v-model="bem.status" />
        </e-col>
      </e-row>

      <e-row mr>

        <e-col>
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input simple-border v-model="bem.id" readonly />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 220px; min-width: 220px">
          <erp-s-field
              view="lr"
              label="Entrada:"
          >
            <datetime-input v-model="bem.dataEntrada" simpleBorder />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 220px; min-width: 220px">
          <erp-s-field
              view="lr"
              label="Saída:"
          >
            <datetime-input v-model="bem.dataSaida" simpleBorder />
          </erp-s-field>
        </e-col>
        <e-col v-if="bemContainer.isVeiculo" style="max-width: 224px">
          <erp-s-field
              view="lr"
              label="Remoção:"
              class="m-r-n-xs"
          >
            <helper-input-btn class="m-l-n-xs">
              <erp-input
                  simple-border
                  v-model="bem.remocao.id"
                  slot="input"
                  readonly
              >
              </erp-input>
              <div class="erp-icon-float">
                <a v-show="bem.remocao" @click="bem.remocao && bemContainer.openRemocao(bem.remocao)"><i
                    class="erp-icon search min"></i> </a></div>
            </helper-input-btn>
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Data Cadastro:"
              label-width="90px"
          >
            <erp-input simple-border :value="$options.filters.formatDate(bem.createdAt)" readonly />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Criado:"
          >
            <erp-input simple-border :value="bem.createdByName" readonly />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Alterado:"
          >
            <erp-input simple-border :value="$options.filters.formatDate(bem.dateModified)" readonly />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Alteração:"
          >
            <erp-input simple-border :value="bem.modifiedByUser" readonly />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <bem-aba-basico
        ref="abaBasico"
        :tipo-bem-selecionado="bemContainer.tipoBemSelecionado"
        :bem="bem"
        secao-campos="tratamento-dados"
        :cache="bemContainer.cache"
        @marcaSelected="(data) => bemContainer.cache.marcaSelected = data"
        @modeloSelected="(data) => bemContainer.cache.modeloSelected = data"
    />
    <checklist ref="checklist" secao="tratamento-dados" />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import BemAbaBasico from "@/components/bem/components/include/BemAbaBasico"
import DatetimeInput from "@/reuse/input/Datetime"
import StatusSelect from "@/components/bem/components/include/StatusSelect"
import SubtipoBemSelect from "@/components/bem/components/include/SubTipoSelect"
import TipoBemSelect from "@/components/bem/components/include/TipoSelect"
import ComitenteInput from "@/components/comitente/helpers/input/ComitenteInput"
import {ErpInput, HelperInputBtn} from "uloc-vue-plugin-erp"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "TratamentoDados",
  components: {
    ComitenteInput,
    TipoBemSelect,
    SubtipoBemSelect,
    StatusSelect,
    DatetimeInput,
    BemAbaBasico,
    ErpInput,
    HelperInputBtn,
    Checklist
  },
  mixins: [CadastroBemMixin],
}
</script>
