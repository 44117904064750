var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"erp-window erp-window-modern bem2-uploader-inline"},[_c('window-uploader',{attrs:{"enable-title-file":true,"enable-validade":true,"permissions":[
        {
          label: 'Público',
          value: 0
        },
        {
          label: 'Arrematante',
          value: 1
        },
        {
          label: 'Comitente',
          value: 2
        },
        {
          label: 'Interno',
          value: 100
        }
      ],"type-file-list":_vm.list,"upload-service":_vm.uploadService,"success-callback":_vm.uploadServiceSuccess}})],1),_c('arquivos-bem',{attrs:{"bem":_vm.bem,"hide-btn":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }