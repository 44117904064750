<template>
  <div>
    <tarefas class="task-layout-inline" layout="inline" relacao-entidade="bem" :relacao-entidade-id="bem.id" :tags="['vistoria', 'manutencao', 'constatacao']" />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import Tarefas from "@/components/sltarefas/components/ListaTarefas"

export default {
  name: "Facilities",
  mixins: [CadastroBemMixin],
  data () {
    return {
    }
  },
  components: {
    Tarefas
  }
}
</script>
