<template>
  <div>
    <h2 class="diviser two-lines">
      Extra
      <span>Informações complementares para o cadastro</span>
    </h2>

    <div class="m-t-lg">
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="URL Google Maps:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoUrlGoogleMaps" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label-width="110px"
              label="URL Street View:"
          >
            <erp-input v-model="bem.localizacaoUrlStreetView" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Embutir mapa no site:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoMapEmbed" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t" mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="Latitude:"
              label-width="110px"
          >
            <erp-input v-model="bem.localizacaoLatitude" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Longitude:"
          >
            <erp-input v-model="bem.localizacaoLongitude" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <erp-s-field class="font-thin font-12" view="lr" label-width="110px">
          Use dados de geolocalização para oferecer uma experiência melhor para os interessados. Esta informação permite ao software apresentar ofertas próximas da localização identificada ou informada pelo interessado. Dados do endereço também são relevantes para esta funcionalidade.
        </erp-s-field>
      </e-row>

      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="extra" />
      </e-row>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SlCheckbox from "@/reuse/input/Checkbox"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"

export default {
  name: "BemExtra",
  components: {
    BemCampoSecao
    // SlCheckbox
  },
  mixins: [CadastroBemMixin],
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
