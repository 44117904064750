<template>
  <div>
    <erp-label v-if="bem.tipo && bemContainer.isVeiculo" tag="div" class="erp-label-white w-full wrapper m-b-lg" label="Acessórios">
      <acessorios-select @change="(s) => this.bem.acessorios = s" v-model="bem.acessorios" :tipo-bem="bem.tipo" />
    </erp-label>

    <erp-label v-if="bemContainer.isVeiculo" tag="div" class="erp-label-white w-full wrapper m-b-lg" label="Demais Informações">
      <div class="row erp-row def-m-r">
        <div class="col" style="max-width: 280px;">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Segurado/Financiado"
          >
            <erp-input v-model="bem.extra.nomeFinanciadoSegurado" />
          </erp-s-field>
        </div>

        <div class="col">
          <erp-s-field
              view="lr"
              label="Contrato Financiamento"
          >
            <erp-input v-model="bem.extra.contratoFinanciamento" />
          </erp-s-field>
        </div>

        <div class="col">
          <tipo-apreensao-select field-view="lr" v-model="bem.tipoApreensao" />
        </div>
      </div>

      <div class="row erp-row def-m-r">
        <div class="col" style="max-width: 280px;">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Assessoria Jurídica"
          >
            <erp-input v-model="bem.extra.assessoriaJuridica" /> <!-- TODO: WindowSearch (Pessoa) -->
          </erp-s-field>
        </div>

        <div class="col">
          <erp-s-field
              view="lr"
              label="Código Filial"
          >
            <erp-input v-model="bem.extra.codigoFilial" />
          </erp-s-field>
        </div>

        <div class="col">
          <erp-s-field
              view="lr"
              label="Vistoriador"
          >
            <erp-input v-model="bem.extra.vistoriador" /> <!-- TODO: WindowSearch (Pessoa) -->
          </erp-s-field>
        </div>
      </div>

      <div class="row erp-row def-m-r">
        <div class="col" style="max-width: 280px;">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Número Sinistro"
          >
            <erp-input v-model="bem.veiculo.sinistro" />
          </erp-s-field>
        </div>

        <div class="col" style="max-width: 400px">
          <erp-s-field
              view="lr"
              label="Número de Atendimento"
          >
            <erp-input v-model="bem.extra.numeroAtendimento" />
          </erp-s-field>
        </div>
      </div>
    </erp-label>

    <div v-if="bem.tipo">
      <div class="m-b font-11">Lista de perícias/observações disponíveis para escolha:</div>
      <pericias-select v-model="bem.pericias" :tipo-bem="bem.tipo" />
    </div>
  </div>
</template>

<script>
import AcessoriosSelect from '../include/AcessoriosSelect'
import TipoApreensaoSelect from '../include/TipoApreensaoSelect'
import PericiasSelect from '../include/PericiasSelect'
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {ErpLabel} from "uloc-vue-plugin-erp"

export default {
  name: "Observacoes",
  components: {
    PericiasSelect,
    TipoApreensaoSelect,
    AcessoriosSelect,
    ErpLabel
  },
  mixins: [CadastroBemMixin]
}
</script>
