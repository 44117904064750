<template>
  <div>
    <h2 class="diviser">
      Remoção
    </h2>

    <div class="m-t-lg" v-if="bemContainer.isImovel">Bem imóvel não possui remoção.</div>
    <div v-else>
      <p class="font-11 font-bold m-t-lg">Localização do bem</p>
      <div class="row m-t-sm" v-if="bem.vaga">
        <div class="col" style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Pátio:"
          >
            <strong>{{ bem.vaga.local.patio.nome }}</strong>
          </erp-s-field>
        </div>
        <div class="col" style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Local:"
          >
            <strong>{{ bem.vaga.local.nome }}</strong>
          </erp-s-field>
        </div>
        <div class="col m-t-n-xs" style="max-width: 160px">
          <erp-s-field
              view="lr"
              label-width="50px"
              label="Vaga:"
          >
            <helper-input-btn>
              <strong>{{ bem.vaga.nome }}</strong>
              <i class="erp-icon search min m-l m-t-xs"></i>
            </helper-input-btn>
          </erp-s-field>
        </div>
      </div>
      <div class="row m-t-sm" v-else>
        Bem não está armazenado no estoque
      </div>

      <div class="m-t-lg">
        <solicitacoes-remocao :solicitacoes="bem.remocao.solicitacoes"/>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import SolicitacoesRemocao from "@/components/remocao/components/window/RemocaoComponents/Solicitacoes"

export default {
  name: "BemRemocao",
  props: {},
  data() {
    return {}
  },
  components: {
    SolicitacoesRemocao
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  computed: {},
  methods: {}
}
</script>
