<template>
  <div>
    <h2 class="diviser m-t-lg">
      Checklist
    </h2>

    <div class="m-t">
      <div v-if="!list || !list.length">Nenhum cheklist encontrado</div>
      <div v-else>
        <div v-if="loading">
          <u-fake-loader/>
          <u-fake-loader width="60%"/>
          <u-fake-loader width="20%"/>
        </div>
        <div class="form-column-align-center" v-else-if="list && list.length">
          <table class="sl-table">
            <thead>
            <th style="white-space: nowrap; max-width: 30px">
              <u-checkbox :color="allChecked ? 'positive' : 'grey-4'" :value="true" disable/>
            </th>
            <th style="white-space: nowrap">Demanda</th>
            <th style="white-space: nowrap">Distribuição</th>
            <th class="text-center text-nowrap">Impeditivo <i class="fal fa-info-circle">
              <u-tooltip :offset="[10,10]">Se a não conclusão do item impede o bem prosseguir para o evento de venda
              </u-tooltip>
            </i></th>
            <th>Último histórico</th>
            <th class="text-center">Situação</th>
            <th class="text-center"></th>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td style="max-width: 30px">
                <u-fake-loader width="100%"/>
              </td>
              <td>
                <u-fake-loader width="100%"/>
              </td>
              <td>
                <u-fake-loader width="100%"/>
              </td>
              <td></td>
              <td>
                <u-fake-loader width="100%"/>
              </td>
              <td></td>
              <td>
                <u-fake-loader width="100%"/>
              </td>
            </tr>
            <tr v-on:dblclick="abrir(model)" v-for="model in list" :key="model.id">
              <td style="max-width: 30px">
                <u-checkbox @input="check(model)" color="grey-7" v-model="model.concluido"/>
              </td>
              <td>
                <div class="m-r">{{ model.demanda }}</div>
              </td>
              <td>
                <div class="m-r">{{ model.createdAt|formatDate }}</div>
              </td>
              <td>{{ model.impeditivo ? 'Sim' : 'Não' }}</td>
              <td>
                <div v-if="model.historico && model.historico.length">
                  {{ model.historico[0].descricao }} <small class="font-thin m-l-xs">({{model.historico[0].pessoa}})</small>
                  <u-tooltip :offset="[10, 10]">{{model.historico[0].data|formatDate}}</u-tooltip>
                </div>
              </td>
              <td>
                <div v-if="model.concluido" class="text-positive">Concluído</div>
                <div v-else class="text-negative">Pendente</div>
              </td>
              <td>
                <e-btn-table-options>
                  <menu-options>
                    <ul>
                      <menu-options-item close label="Excluir" @click="excluir(model)" label-class="text-negative"/>
                    </ul>
                  </menu-options>
                </e-btn-table-options>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="bem.id" class="m-t-lg">
        <div v-if="allChecked" class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        <u-btn @click="() => gerarChecklist(evento, secao)" class="sl-btn text-black" no-caps color="grey-3"
               label="Gerar checklist automaticamente"/>
      </div>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {URadio, UCheckbox, UFakeLoader} from "uloc-vue"
import {EBtnTableOptions, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {gerar, list, updateChecklist, deleteChecklist} from "@/domain/bem/services/checklist"

export default {
  name: "Checklist",
  components: {
    // BemCampoSecao,
    // URadio,
    UCheckbox,
    UFakeLoader,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem,
    // ErpSField,
    // ErpSelect
  },
  props: {
    secao: {
      required: false
    },
    evento: {
      required: false
    }
  },
  mounted() {
    this.bem.id && this.load()
  },
  mixins: [CadastroBemMixin],
  data() {
    return {
      loading: false,
      list: null,
      isDescMin: false
    }
  },
  watch: {},
  computed: {
    allChecked () {
      if (!this.list || !this.list.length) {
        return false
      }
      return !this.list.filter(c => !c.concluido).length
    }
  },
  methods: {
    load() {
      const filtros = []
      if (this.secao) {
        filtros.push('codigoSecao=' + this.secao)
      }
      this.loading = true
      list(this.bem.id, 1000, 1, '&' + filtros.join('&'))
          .then(response => {
            this.loading = false
            this.list = response.data.result
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    gerarChecklist(evento = null, secao = null) {
      this.$uloc.dialog({
        title: 'Gerar checklist',
        message: 'Você tem certeza que deseja gerar o checklist baseado no tipo de bem?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Gerando...'})
        const extra = []
        if (evento) {
          extra.push('codigoEvento=' + evento)
        }
        if (secao) {
          extra.push('codigoSecao=' + secao)
        }
        gerar(this.bem.id, '?' + extra.join('&'))
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    check(model) {
      this.$nextTick(() => {
        this.atualizaConclusaoChecklist(model)
      })
    },
    atualizaConclusaoChecklist(model) {
      updateChecklist({
        id: model.id,
        concluido: model.concluido
      }, 'PATCH')
          .then(response => {
            console.log('OK')
            if (response.data._criados && Number(response.data._criados) > 0) {
              let message = `Item atualizado scom sucesso e foram criados ${response.data._criados} novas demandas de checklist`
              this.$uloc.notify({color: 'black', position: 'bottom', message: message})
              this.load()
            } else {
              let message = 'Item atualizado scom sucesso.'
              this.$uloc.notify({color: 'positive', position: 'bottom', message: message})
            }
          })
          .catch(error => {
            this.alertApiError(error)
            model.concluido = !model.concluido
          })
    },
    excluir(model) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteChecklist(model.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
