var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('e-row',{staticClass:"bem-aprovacao",attrs:{"mr":""}},[_c('e-col',{staticClass:"form-column-align-center"},[_c('div',{staticClass:"font-bold m-t"},[_vm._v("Evento")]),_c('div',{staticClass:"m-t-sm"},[_c('evento-select',{ref:"eventoSelect",attrs:{"disable":!_vm.bem.id,"label":' '},model:{value:(_vm.bem.evento),callback:function ($$v) {_vm.$set(_vm.bem, "evento", $$v)},expression:"bem.evento"}})],1),_c('div',{staticClass:"font-bold m-t"},[_vm._v("Status atual")]),_c('div',{staticClass:"m-t-sm"},[_c('erp-select',{attrs:{"disable":!_vm.bem.id,"options":[
                  {label: 'Saneamento', value: 0},
                  {label: 'Providências', value: 1},
                  {label: 'Formalização', value: 2},
                  {label: 'Venda', value: 3},
                  {label: 'Concluído', value: 100},
              ]},model:{value:(_vm.bem.eventoInterno),callback:function ($$v) {_vm.$set(_vm.bem, "eventoInterno", $$v)},expression:"bem.eventoInterno"}})],1)])],1),_c('checklist',{ref:"checklist",attrs:{"secao":"objetivo"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }