import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'bem.historico-financeiro.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Tipo do Débito', name: 'tipo', active: true, sortable: false, ordering: 1},
    {label: 'Valor', name: 'valor', active: true, sortable: false, ordering: 2},
    {label: 'Juros', name: 'juros', active: true, sortable: false, ordering: 3},
    {label: 'Multa', name: 'multa', active: true, sortable: false, ordering: 4},
], listVersion)
