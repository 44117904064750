<template>
  <div>
    <h2 class="diviser">
      Resumo dos débitos registrados no financeiro
    </h2>

    <div class="m-t">
      <!--      <sl-table :columns="listStorage" :loading="loading">
              <template v-if="listData && listData.length" v-slot:body>
                <sl-table-tr v-for="data in listData" :key="data.id">
                  <sl-table-td>{{data.nome}}</sl-table-td>
                  <sl-table-td>{{data.inicio|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.fim|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.motivo}}</sl-table-td>
                </sl-table-tr>
              </template>
              <template v-else v-slot:body>
                <tr>
                  <td colspan="100%">Nenhum dado cadastrado.</td>
                </tr>
              </template>
            </sl-table>-->
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-w-table simple-bottom no-scroll m-t-md"
          :rows-per-page-options="[20, 50, 100, 1000]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :style="col.name === 'motivo' ? 'text-align: right !important' : ''" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-right">
              <span class="cursor-pointer" @click="listOptions"></span>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="click(props.row)" slot="body" slot-scope="props"
              :props="props">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="tipo" :props="props">
          </u-td>
          <u-td key="valor" :props="props">
          </u-td>
          <u-td key="juros" :props="props">
          </u-td>
          <u-td key="multa" :props="props">
          </u-td>
          <u-td key="options" class="text-right" style="max-width: 100px" :props="props">
<!--            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                           @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>-->
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <checklist ref="checklist" secao="negocial-condicoes" />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {resumo} from "@/domain/bem/services/financeiro"
import {listStorage} from "@/components/bem2/components/include/historico-financeiro/config/list"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import Checklist from "@/components/bem2/components/include/Checklist"

export default {
  name: "HistoricoFinanceiro",
  data() {
    return {
      listStorage: this.listStorage,
      loading: false,
      listData: null
    }
  },
  components: {
    Checklist
  },
  mounted() {
    this.bem.id && this.load()
    this.table.serverPagination.rowsPerPage = 1000
  },
  mixins: [CadastroBemMixin, ListMixin, GlobalPersonMixin],
  watch: {},
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {},
  methods: {
    request({pagination, filter}) {
      console.log('Loading')

      this.table.loading = true
      const extraFilters = []
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('')}`)

      resumo(this.bem.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    click (t) {}
  }
}
</script>
