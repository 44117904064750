<template>
  <div>
    <e-row mr class="bem-aprovacao">
      <e-col class="form-column-align-center">
        <div class="font-bold m-t">Evento</div>
        <div class="m-t-sm">
          <evento-select :disable="!bem.id" ref="eventoSelect" :label="' '" v-model="bem.evento" />
        </div>
        <div class="font-bold m-t">Status atual</div>
        <div class="m-t-sm">
          <erp-select :disable="!bem.id" v-model="bem.eventoInterno" :options="[
                    {label: 'Saneamento', value: 0},
                    {label: 'Providências', value: 1},
                    {label: 'Formalização', value: 2},
                    {label: 'Venda', value: 3},
                    {label: 'Concluído', value: 100},
                ]"/>
        </div>
      </e-col>
    </e-row>

    <checklist ref="checklist" secao="objetivo"  />
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import EventoSelect from "@/components/bem/components/include/EventoSelect"
import Checklist from "@/components/bem2/components/include/Checklist"
import {ErpSelect} from "uloc-vue-plugin-erp"

export default {
  name: "SaneamentoObjetivo",
  mixins: [CadastroBemMixin],
  data () {
    return {
      backupEvento: this.bemContainer.bem.evento
    }
  },
  watch: {
    'bem.evento' (v) {
      if (!v) return
      this.$uloc.dialog({
        title: 'Você está prestes a mudar o evento do bem',
        message: 'Ao mudar o evento, demandas serão geradas automaticamente para o bem',
        ok: 'Sim',
        cancel: 'Não',
        color: 'positive'
      }).then(() => {
        const evento = this.$refs.eventoSelect.asyncdata.find(obj => obj.id === this.bem.evento)
        console.log('Código: ', evento.codigo)
        this.$refs.checklist.gerarChecklist(evento.codigo, null)
        this.backupEvento = this.bem.evento
      }).catch(() => {
        this.bem.evento = this.backupEvento
      })
    }
  },
  components: {
    Checklist,
    EventoSelect,
    ErpSelect
  }
}
</script>
