<template>
  <div class="pos-rlt bem-aba" :class="{disable: disable}">
<!--    <div v-if="disable"
         style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; user-select: all; z-index: 2"></div>-->
    <e-row>
      <e-col>
        <!-- VEICULO -->
        <e-row v-if="isVeiculo">
          <e-row mr>
            <e-col style="max-width: 260px; min-width: 260px">
              <erp-s-field
                  view="lr"
                  label-width="100px"
                  label="Placa:"
              >
                <erp-input class="input-uppercase" v-model="bem.placa" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="lr"
                  label="Chassi:"
              >
                <erp-input maxlength="17" class="input-uppercase" v-model="bem.chassi" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="lr"
                  label="Renavam:"
              >
                <erp-input v-model="bem.renavam" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row mr>
            <e-col style="max-width: 260px; min-width: 260px">
              <erp-s-field
                  view="lr"
                  label="UF da Placa:"
                  label-width="100px"
              >
                <uf-select v-model="bem.ufPlaca" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="lr"
                  label="Quilometragem:"
              >
                <erp-input v-model="bem.km" />
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="lr"
                  label="Código Fipe:"
              >
                <erp-input v-model="bem.codigoFipe" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row mr>
            <e-col style="min-width: 260px">
              <marca-select :tipo="bem.tipo" placeholder="Selecione" label-width="100px" field-view="lr"
                            label="Montadora/Marca:"
                            :finded-data-obj="cache.marcaSelected"
                            @updateSelected="(data) => $emit('marcaSelected', data)"
                            v-model="bem.marca" />
            </e-col>
            <e-col style="margin-right: 0">
              <modelo-select :tipo="bem.tipo" :disable="!bem.marca" :marca="bem.marca" placeholder="Selecione" field-view="lr"
                             label="Modelo:"
                             :finded-data-obj="cache.modeloSelected"
                             @updateSelected="modeloSelectEvent"
                             v-model="bem.modelo" />
            </e-col>
          </e-row>

          <e-row mr style="width: 100%">
            <e-col>
              <fipe-ano-select ref="fipeAno" label-width="100px" :modelo="bem.modelo" field-view="lr" v-model="bem.fipeAnoModelo" @anoSelected="anoFipeSelect" />
            </e-col>
            <e-col style="margin-right: 0">
              <fipe-ano-fabricacao-select ref="fipeAnoFab" field-view="lr" v-model="bem.anoFabricacao" />
            </e-col>
          </e-row>
          <div class="row erp-row def-m-r">
            <e-col v-show="!bem.fipeAnoModelo" style="max-width: 180px; min-width: 180px">
              <erp-s-field
                  view="lr"
                  label-width="100px"
                  label="Ano Modelo:"
              >
                <erp-input v-model="bem.anoModelo" />
              </erp-s-field>
            </e-col>
            <e-col v-show="!bem.fipeAnoModelo" style="max-width: 150px; min-width: 150px">
              <erp-s-field
                  view="lr"
                  label="Ano Fabricação:"
              >
                <erp-input v-model="bem.anoFabricacao" />
              </erp-s-field>
            </e-col>

            <div class="col">
              <cor-select label-width="100px" placeholder="Selecione" field-view="lr" label="Cor predominante:"
                          v-model="bem.cor" />
            </div>
          </div>

          <e-row class="def-m-r">
            <e-col style="max-width: 260px; min-width: 260px">
              <combustivel-select label-width="100px" placeholder="Selecione" field-view="lr" label="Combustível:"
                                  v-model="bem.tipoCombustivel" />
            </e-col>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Local no pátio:"
              >
                <erp-input v-model="bem.local" />
              </erp-s-field>
            </e-col>
          </e-row>

          <e-row class="row erp-row def-m-r">
            <e-col style="max-width: 210px; min-width: 210px">
              <erp-s-field
                  view="lr"
                  label-width="100px"
                  tag="div"
                  style="line-height: 20px"
              >
                <label for="liga" class="no-select font-bold">
                  <input type="checkbox" v-model="bem.liga" id="liga">
                  <span class="m-l-xs">Veículo Liga</span>
                </label>
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 210px; min-width: 210px">
              <erp-s-field
                  view="lr"
                  tag="div"
                  style="line-height: 20px"
              >
                <label for="possuiChave" class="no-select font-bold">
                  <input type="checkbox" v-model="bem.possuiChave" id="possuiChave">
                  <span class="m-l-xs">Possui Chave</span>
                </label>
              </erp-s-field>
            </e-col>
          </e-row>
        </e-row>

        <e-row v-if="isVeiculo" mr>
          <e-col style="max-width: 200px; min-width: 200px">
            <erp-s-field
                view="lr"
                label-width="100px"
                label="Código da chave:"
            >
              <erp-input class="sl-input" :disable="!bem.possuiChave" v-model="bem.codigoChave" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 160px; min-width: 160px">
            <erp-s-field
                view="lr"
                label="Local da chave:"
            >
              <erp-input :disable="!bem.possuiChave" v-model="bem.localChave" />
            </erp-s-field>
          </e-col>
          <e-col>
              <situacao-chave-select :disable="!bem.possuiChave" placeholder="Selecione" field-view="lr" label="Situação da chave:"
                                     v-model="bem.situacaoChave" />
          </e-col>
        </e-row>

        <e-row v-if="isVeiculo" mr>
          <e-col>
            <erp-s-field
                view="lr"
                label-width="100px"
                label="Número Sinistro"
            >
              <erp-input v-model="bem.veiculo.sinistro" />
            </erp-s-field>
          </e-col>
        </e-row>
        <!-- FIM VEICULO -->

        <!-- IMOVEL -->
        <div v-if="isImovel">
          <e-row mr>
            <e-col>
              <erp-s-field
                  view="lr"
                  label-width="100px"
                  label="Código Interno:"
              >
                <erp-input v-model="bem.codigo" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field view="lr" label-width="100px" label="País">
                  <erp-select v-model="bem.pais" :options="[
                    {label: 'Brasil', value: '1'},
                ]"/>
                </erp-s-field>
              </e-col>
              <e-col class="cep-input" style="max-width: 280px">
                <erp-s-field view="lr" label="CEP">
                  <cep-input @cep="cep" v-model="bem.cep" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field view="lr" label-width="100px" label="Endereço">
                  <erp-input v-model="bem.endereco"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field view="lr" label="Número">
                  <erp-input ref="addressNumber" v-model="bem.numero"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field view="lr" label-width="100px" label="Complemento">
                  <erp-input v-model="bem.endComplemento"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field view="lr" label="Bairro">
                  <erp-input v-model="bem.bairro"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field view="lr" label-width="100px" label="UF">
                  <uf-select :city="bem.cidade" v-model="bem.uf" @preventCitySelected="(city) => bem.cidade = city" />
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field view="lr" label="Cidade">
                  <cidade-select :uf="bem.uf" v-model="bem.cidade" />
                </erp-s-field>
              </e-col>
            </e-row>
<!--            <div class="row erp-row def-m-r">
              <div class="col" style="max-width: 260px; min-width: 260px">
                <erp-s-field
                    view="lr"
                    label-width="100px"
                    label="Cidade:"
                >
                  <erp-input v-model="bem.cidade" />
                </erp-s-field>
              </div>

              <div class="col">
                <erp-s-field
                    view="lr"
                    label="Bairro:"
                >
                  <erp-input v-model="bem.bairro" />
                </erp-s-field>
              </div>

              <div class="col">
                <erp-s-field
                    view="lr"
                    label="UF:"
                >
                  <erp-input v-model="bem.uf" />
                </erp-s-field>
              </div>
            </div>-->

            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="100px"
                    label="Matrícula:"
                >
                  <erp-input v-model="bem.identificador" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="100px"
                    label="Número do Cartório:"
                >
                  <erp-input v-model="bem.cartorio" />
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Local do Cartório:"
                >
                  <erp-input v-model="bem.imovel.localCartorio" />
                </erp-s-field>
              </e-col>
            </e-row>
<!--            <div class="row erp-row def-m-r">

              <div class="col" style="max-width: 260px; min-width: 260px">
                <erp-s-field
                    view="lr"
                    label-width="100px"
                    label="Inscrição Municipal:"
                >
                  <erp-input v-model="bem.inscricaoMunicipal" />
                </erp-s-field>
              </div>
            </div>

            <e-row mr class="def-m-r">
              <e-col style="max-width: 260px; min-width: 260px">
                <erp-s-field
                    view="lr"
                    label-width="100px"
                    label="Área Edificada:"
                >
                  <erp-input v-model="bem.areaEdificada" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Área Terreno:"
                >
                  <erp-input v-model="bem.areaTerreno" />
                </erp-s-field>
              </e-col>
            </e-row>-->
          </e-col>
        </e-row>
        </div>
        <!-- FIM IMOVEL -->

<!--        <bem-campos-extras :bem="bem" v-if="bem.camposExtras" />-->

        <e-row mr class="m-t" :class="{'hide': isImovel}">
          <e-col style="max-width: 300px">
            <conservacao-select placeholder="Selecione" label-width="100px" field-view="lr" label="Conservação:"
                                v-model="bem.conservacao" />
          </e-col>
        </e-row>

<!--        <e-row mr v-if="isImovel">
          <e-col style="max-width: 260px">
            <erp-s-field view="lr" label-width="100px" label="Imóvel ocupado?">
              <erp-select v-model="bem.imovel.ocupado" :options="[
                {label: 'Sim', value: true},
                {label: 'Não', value: false},
            ]" />
            </erp-s-field>
          </e-col>
        </e-row>-->

        <e-row v-if="isVeiculo" mr>
          <e-col style="max-width: 360px; min-width: 360px">
            <erp-s-field
                view="lr"
                label-width="100px"
                tag="div"
                style="line-height: 20px"
            >
              <label for="alagamento" class="no-select font-bold">
                <input type="checkbox" v-model="bem.alagamento" id="alagamento">
                <span class="m-l-xs">Veículo proveniente de alagamento</span>
              </label>
            </erp-s-field>
          </e-col>
          <div class="col" >
            <erp-s-field
                view="lr"
                label="Data da Higienização:"
                label-width="100px"
            >
              <datetime-input date-format="##/##/####" date-type="date" v-model="bem.dataHigienizacao"
                              :disable="!bem.alagamento" />
              <!-- TODO: BUG -->
            </erp-s-field>
          </div>
        </e-row>



      </e-col>
      <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
        <imagem :disable="disable" label="Foto Principal" legend="De preferência 800x600px" ref="imagem"
                :image="bem.image"
                @update="updateImagem" @toDelete="deleteImagemEvent" />
      </e-col>
    </e-row>
    <e-row style="margin-left: 110px">
      <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="principal" />
    </e-row>

    <!-- SITE -->

    <!--<div class="m-t-md" v-if="bem.camposExtra">
      <erp-label tag="div" class="erp-label-white w-full" label="Campos Extras">
        &lt;!&ndash; CARREGAR DINAMICAMENTE &ndash;&gt;
        <div class="row erp-row m-l">
          <erp-s-field
              view="lr"
              label-width="100px"
              label="Classificação do veículo:"
          >
            <div class="flex items-center">
              <div class="flex items-center m-r-xs">
                <u-radio color="black" v-model="bem.camposExtra[0].value" val="P" class="m-r-xxs" />
                P
              </div>
              <div class="flex items-center m-r-xs">
                <u-radio color="black" v-model="bem.camposExtra[0].value" val="M" class="m-r-xxs" />
                M
              </div>
              <div class="flex items-center m-r-xs">
                <u-radio color="black" v-model="bem.camposExtra[0].value" val="G" class="m-r-xxs" />
                G
              </div>
              <div class="flex items-center m-r-xs">
                <u-radio color="black" v-model="bem.camposExtra[0].value" val="S" class="m-r-xxs" />
                S
              </div>
            </div>
          </erp-s-field>
        </div>
      </erp-label>
    </div>-->
  </div>
</template>

<script>
/* TODO: EMIT DATA TO PARENT */
/* TODO: Campo Extra quando utilizado na janela do Lote */
import MarcaSelect from '../include/MarcaSelect'
import ModeloSelect from '../include/ModeloSelect'
import CorSelect from '../include/CorSelect'
import CombustivelSelect from '../include/CombustivelSelect'
import ConservacaoSelect from '../include/ConservacaoSelect'
// import {UEditor, URadio} from 'uloc-vue'
import DatetimeInput from '../../../../reuse/input/Datetime'
import {
  ErpSField,
  ErpInput,
  ErpSelect
  // ErpLabel,
  // ErpCheckbox
} from 'uloc-vue-plugin-erp'
// import BtnMostrar from '../../../../reuse/btn/Mostrar'
import ERow from '../../../layout/components/Row'
import TipoBemMixin from './TipoBemMixin'
import ECol from '../../../layout/components/Col'
import Imagem from '../../../../reuse/upload/Imagem'
import {deleteFotoBem} from '@/domain/bem/services'
// import BemCamposExtras from './CamposExtras'
import FipeAnoSelect from '@/components/bem/components/include/FipeAnoSelect'
import FipeAnoFabricacaoSelect from "@/components/bem/components/include/FipeAnoFabricacaoSelect"
import SituacaoChaveSelect from "@/components/bem/components/include/SituacaoChaveSelect"
import CepInput from "@/components/pessoa/components/include/CepInput"
import UfSelect from "@/components/pessoa/components/include/UfSelect"
import CidadeSelect from "@/components/pessoa/components/include/CidadeSelect"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao"

export default {
  name: 'BemAbaBasico',
  mixins: [TipoBemMixin],
  props: ['bem', 'tipoBemSelecionado', 'cache', 'disable'],
  data () {
    return {
      mostrarEditorObservacoesSite: false,
      mostrarEditorObservacoesSiteSource: false,
      deleteImagem: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.disable) {
        //let tags = this.$el.getElementsByTagName('label');
        //for( let i = 0; i < tags.length; i++) {
          //let fields = tags[i].querySelectorAll('input,select,textarea')
          let fields = this.$el.querySelectorAll('input,select,textarea')
          if (fields.length) {
            for( let i2 = 0; i2 < fields.length; i2++) {
              fields[i2].setAttribute('id', fields[i2].getAttribute('id') + '__readonly')
              fields[i2].disabled = true
            }
          }
        //}
      }
      if (this.cache.modeloSelected && this.cache.modeloSelected.id) {
        this.$refs.fipeAno && this.$refs.fipeAno.load(this.cache.modeloSelected.id)
      }
    })
  },
  components: {
    BemCampoSecao,
    CidadeSelect,
    CepInput,
    SituacaoChaveSelect,
    FipeAnoFabricacaoSelect,
    FipeAnoSelect,
    // BemCamposExtras,
    Imagem,
    ECol,
    ERow,
    // BtnMostrar,
    UfSelect,
    MarcaSelect,
    ModeloSelect,
    CorSelect,
    CombustivelSelect,
    ConservacaoSelect,
    // UEditor,
    // URadio,
    ErpSField,
    ErpInput,
    // ErpLabel,
    // ErpCheckbox,
    DatetimeInput,
    ErpSelect
  },
  methods: {
    deleteImagemEvent () {
      this.deleteImagem = true
      deleteFotoBem(this.bem.id)
        .then((response) => {
          this.bem.image = null
          console.log(response.data)
        })
        .catch(error => {
          console.log('Não foi possível remover a foto do bem')
          console.error(error)
        })
    },
    updateImagem (file) {
      this.$imagem = file
    },
    modeloSelectEvent (data) {
      console.log('MODELO SELECTED', data)
      this.$refs.fipeAno.load(data.id)
      this.$emit('modeloSelected', data)
      console.log('DESC', this.bem.descricao)
      if (!this.bem.descricao || !String(this.bem.descricao).length) {
        this.bem.descricao = data.marca.nome + ' ' + data.nome
      }
    },
    anoFipeSelect (d) {
      this.$refs.fipeAnoFab.define(d)
      let n = Number(String(d).replace(/\D/gi, ''))
      if (n === 0) {
        this.bem.anoModelo = (new Date()).getFullYear()
      } else {
        this.bem.anoModelo = n
      }
    },
    cep (data) {
      this.$refs.addressNumber.focus()
      this.bem.endereco = data.logradouro
      this.bem.complemento = data.complemento
      this.bem.bairro = data.bairro
      setTimeout(() => {
        this.bem.cidade = data.localidade
        // this.bem.cityId = data.ibge
      }, 100)
      this.bem.uf = data.uf
    }
  }
}
</script>
